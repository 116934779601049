import React from 'react';
import { Link } from 'gatsby';
import { Seo } from '../../../modules/common/components/seo';
import { DashboardLayout } from '../../../modules/layout/components/dashboard-layout';
import { StaticImage } from 'gatsby-plugin-image';
import { SectionHeader } from '../../../modules/common/components/section-header';

import '../../generic-page.scss';
import { Alert, Table } from 'react-bootstrap';
import { EversoulCharacter } from '../../../modules/es/common/components/eversoul-character';

const EsGuidesIAira: React.FC = () => {
  return (
    <DashboardLayout className={'generic-page'} game="es">
      <ul className="breadcrumb">
        <li>
          <Link to="/eversoul/">Eversoul</Link>
        </li>
        <li className="divider">/</li>
        <li>Subjugation - Aira</li>
      </ul>
      <div className="page-header">
        <div className="page-icon">
          <StaticImage
            src="../../../images/eversoul/categories/category_aira.png"
            alt="Subjugation - Aira"
          />
        </div>
        <div className="page-details">
          <h1>Subjugation - Aira</h1>
          <h2>
            Struggling with the Subjugation - Aira? This guide will help you!
          </h2>
        </div>
      </div>
      <div className="fuse-ad-placeholder" data-fuse="22844297235"></div>
      <div className="page-content">
        <SectionHeader title="Aira Mechanics" />
        <Alert variant="primary">
          This guide was written for difficulty level 301, so not all mechanics
          may apply if you are doing a lower difficulty. Higher difficulty skill
          alterations for level 320+ are also not included. If you are unsure,
          read the skill descriptions provided in this guide!
        </Alert>
        <h5>Groggy bar</h5>
        <StaticImage
          src="../../../images/eversoul/aira/image10.webp"
          alt="Aira Sub"
        />
        <p>
          The same subjugation mechanic as all the rest, Aira has a bar that
          makes her immune to all CC. As of the Ayame ESS, all hard CC (stun,
          sleep, silence, charm) will bring this bar down (previously was only
          stuns). When the bar is depleted, Aira will go into groggy mode for a
          duration. It will also fully replenish all your characters’ mana bars
          and activate their ultimates, so using a charged ultimate before fully
          depleting the bar is ideal. Unlike in other ESS however, spamming your
          main skills is not recommended! This will be explained in detail
          later.
        </p>
        <p>
          It is also important to note that starting with the Ayame ESS, the 4
          types of hard CC were given values and some provided diminished
          reduction in the groggy bar. For Aira, sleeps are the best CC to use
          but in the current roster, only Naiah has them. Stun, silence and
          charm will still work but only at 50% efficiency.
        </p>
        <StaticImage
          src="../../../images/eversoul/aira/image7.webp"
          alt="Aira Sub"
        />
        <p>
          Breaking her stun bar will also put a 100% received damage debuff on
          Aira for the duration of the stun, making it the ideal time to use
          your best DPS ultimates.
        </p>
        <StaticImage
          src="../../../images/eversoul/aira/image5.webp"
          alt="Aira Sub"
        />
        <p>
          This stun bar mechanic is very important to making your life easier in
          subjugation, as the amount you remove from it in battle carries over
          from team to team. Keeping this in mind, you can use some “throwaway”
          CC teams in between your main DPS teams to deplete the bar prior to a
          fight. It’s also very important to make sure you don’t deplete it by
          accident in the last few seconds of a fight when you’ve already used
          up your good DPS ultimates, allowing it to carry over for your next
          team to start a fresh rotation.{' '}
        </p>
        <h5>Madness</h5>
        <p>
          The main mechanic to the Aira ESS is keeping her Madness stacks in
          check. All of her other skills revolve around this mechanic, so it is
          important to understand how it works, and how to prevent it from
          stacking up and killing you. Each stack increases Aira’s Atk by 5% and
          damage received by 3%, so there is some small benefit to the stacks,
          but not so much if you’re dead.
        </p>
        <h5>Erupting Madness</h5>
        <p>
          The main mechanic to this fight that probably causes the most
          self-inflicted death. Each time you use a character’s main skill, Aira
          will get a stack of Erupting Madness buff. The buff stack lasts 3
          seconds, and using another character’s skill within this time will
          give her the current number of Erupting Madness stacks worth of
          Madness, plus give her another stack of Erupting Madness, up to three
          times.
        </p>
        <StaticImage
          src="../../../images/eversoul/aira/image1.webp"
          alt="Aira Sub"
        />
        <p>
          So for example, if you main skill once, wait three seconds for the
          buff to disappear, then skill again, Aira will gain nothing.
        </p>
        <p>
          If you skill once, then skill again before Erupting Madness wears off,
          she will gain 1 stack of Madness, and 1 stack of Erupting Madness -
          leaving her with 2 stacks of Erupting Madness.
        </p>
        <p>
          If you skill once more while she has 2 stacks of Erupting Madness, she
          will gain 2 stacks of Madness, and another stack of Erupting Madness -
          maxing out at 3 stacks.
        </p>
        <p>
          Basically said, if you spam all of your skills one after the other
          without waiting for this debuff to wear off, she will stack madness
          very very quickly, which causes all of her skills to do increased
          damage and apply various debuffs to your team. The most common debuff
          being death.{' '}
          <strong>
            This mechanic DOES NOT stop working while she is in groggy state.
            Keep this in mind when using skills during the DPS phase.
          </strong>
        </p>
        <h5>Pent-up Madness</h5>
        <p>
          Good news, though! Aira can also passively generate her own Madness
          stacks even if you’re careful in timing your skill usage. /s
        </p>
        <StaticImage
          src="../../../images/eversoul/aira/image3.webp"
          alt="Aira Sub"
        />
        <p>
          The “certain number” is 3, and a debuff is basically anything your
          characters apply to Aira. This includes things like Atk/Def down, and
          even DoTs. As long as there are 3, she will not gain any stacks, as
          can be seen here:
        </p>
        <StaticImage
          src="../../../images/eversoul/aira/image2.webp"
          alt="Aira Sub"
        />
        <p>
          This makes party building a bit more tricky, unless you choose to
          ignore this mechanic. Pent-up Madness lasts for 10 seconds, and gives
          her a stack of Madness every 2 seconds she doesn’t have 3 debuffs on
          her though, so Madness stacks can add up pretty quickly if you choose
          to ignore it.
        </p>
        <h5>Uncontrollable Madness and Juggling Targets</h5>
        <p>
          Another method to survival is being able to juggle Aira’s target by
          taking advantage of the contrast between her passive and two of her
          skills. I’ll CRUSH you! and Do not Disturb! will always target the
          furthest target from Aira, but her passive Uncontrollable Madness will
          cause her to swap targets to whoever used a main skill last. It is
          possible to split her damage by using main skills at the right time to
          get her to attack one target, then turn away and attack another
          target. Doing this can prevent the same targets being hit multiple
          times in a row and getting killed.{' '}
        </p>
        <h5>Main and Sub skills</h5>
        <p>
          Unlike other ESS, Aira does not use mana, and therefore doesn’t care
          about mana reduction. Her main and sub skills scale based on how many
          Madness stacks she’s acquired, which can start to cause problems if
          you don’t manage them well.
        </p>
        <p>
          As always, the higher difficulty you take on, the more the skills will
          hurt.{' '}
        </p>
        <Table striped bordered responsive className="banner-history">
          <thead>
            <tr>
              <th>Skill Typ</th>
              <th>Skill Name</th>
              <th>Lv.</th>
              <th>Details</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td rowSpan={4}>Main</td>
              <td rowSpan={4}>I&rsquo;ll CRUSH you!</td>
              <td>Basic</td>
              <td>
                The mad beast focuses on the furthest enemy as she strikes the
                ground with enormous strength. She deals 300% ATK damage in a
                20m long, 180 degree angle. This attack cannot be dodged.
              </td>
            </tr>
            <tr>
              <td>Lv. 201</td>
              <td>
                Basic damage changes to 400%, and gains the following effects
                depending on number of Madness stacks. 10 or more stacks:
                &ndash; Deals additional 200% ATK damage. 20 or more stacks:
                &ndash; Decreases DEF of hit enemies by 50% for 20 seconds.
              </td>
            </tr>
            <tr>
              <td>Lv. 301</td>
              <td>
                Hit enemies are put into a &ldquo;Weakened&rdquo; state for 20
                seconds, and receive additional 1500% ATK damage when they get
                hit by the next &ldquo;I&rsquo;ll CRUSH you!&rdquo; attack.
              </td>
            </tr>
            <tr>
              <td>Lv. 400</td>
              <td>
                Gains the following effects depending on number of Madness
                stacks. 5 or more stacks &ndash; Hit enemies are stunned for 4
                seconds. 10 or more stacks &ndash; Deals additional 200% ATK
                damage. 15 or more stacks &ndash; Decreases DEF of hit enemies
                by 50% for 20 seconds. 20 or more stacks &ndash; Deals
                additional 2000% ATK damage.
              </td>
            </tr>
            <tr>
              <td rowSpan={4}>Sub</td>
              <td rowSpan={4}>Do not disturb!</td>
              <td>Basic</td>
              <td>
                The mad beast runs toward the furthest enemy and swings her
                hammer in 360 degrees, while removing 1 of her debuff and
                dealing 150% ATK damage to enemies within 3m and pushing them
                back.
              </td>
            </tr>
            <tr>
              <td>Lv. 120</td>
              <td>Damage changes to 200%.</td>
            </tr>
            <tr>
              <td>Lv. 220</td>
              <td>
                Gains the following effects depending on number of Madness
                stacks. 10 or more stacks &ndash; Deals additional 100% ATK
                damage. 20 or more stacks &ndash; Reduces hit enemy&rsquo;s Mana
                by 2000.
              </td>
            </tr>
            <tr>
              <td>Lv. 320</td>
              <td>
                Gains the following effects depending on number of Madness
                stacks. 5 or more stacks &ndash; Additionally removes 1 of her
                debuff.&nbsp; 10 or more stacks &ndash; Deals additional 100%
                ATK damage.&nbsp; 15 or more stacks &ndash; Reduces hit
                enemy&rsquo;s Mana by 2000.&nbsp; 20 or more stacks &ndash; Hit
                enemies are stunned for 8 seconds (this effect cannot be
                removed).
              </td>
            </tr>
            <tr>
              <td rowSpan={4}>Sub</td>
              <td rowSpan={4}>I shall destroy everything!</td>
              <td>Basic</td>
              <td>
                The mad beast strikes down with shattering force, dealing 300%
                ATK damage to all enemies.
              </td>
            </tr>
            <tr>
              <td>Lv. 140</td>
              <td>Damage changes to 400%.</td>
            </tr>
            <tr>
              <td>Lv. 240</td>
              <td>
                Gains the following effects depending on number of Madness
                stacks. 10 or more stacks &ndash; Deals additional 200% ATK
                damage. 20 or more stacks &ndash; Hit enemies are knocked down.
              </td>
            </tr>
            <tr>
              <td>Lv. 340</td>
              <td>
                Gains the following effects depending on number of Madness
                stacks. 5 or more stacks &ndash; Reduces Mana recovery by 25%
                for 12 seconds. 10 or more stacks &ndash; Deals additional 200%
                ATK damage. 15 or more stacks &ndash; Hit enemies are knocked
                down. 20 or more stacks &ndash; Permanently reduces HP recovery
                by 50% (this effect cannot be removed).
              </td>
            </tr>
            <tr>
              <td rowSpan={4}>Sub</td>
              <td rowSpan={4}>I&rsquo;ll SMASH you!</td>
              <td>Basic</td>
              <td>
                The made beast leaps up high, and strikes down, dealing 100% ATK
                damage to all enemies.
              </td>
            </tr>
            <tr>
              <td>Lv. 160</td>
              <td>Damage changes to 150%.</td>
            </tr>
            <tr>
              <td>Lv. 260</td>
              <td>
                Gains the following effects depending on number of Madness
                stacks. 10 or more stacks &ndash; Slows by 20% for 12 seconds.
                20 or more stacks &ndash; Deals additional 100% ATK damage and
                knocks target down.
              </td>
            </tr>
            <tr>
              <td>Lv. 360</td>
              <td>
                Gains the following effects depending on number of Madness
                stacks. 5 or more stacks &ndash; Slows by 20% for 12 seconds. 10
                or more stacks &ndash; Deals additional 100% ATK damage and
                knocks them down. 15 or more stacks &ndash; Creates a shield
                with 3% of her HP. 20 or more stacks &ndash; Permanently
                increases ATK by 100%, and its effect cannot be removed (can be
                stacked).
              </td>
            </tr>
            <tr>
              <td rowSpan={5}>Sub</td>
              <td rowSpan={5}>Uncontrollable Madness</td>
              <td>Basic</td>
              <td>
                Gains a &ldquo;Erupting Madness&rdquo; buff for 3 seconds if her
                enemy uses a main skill, and then permanently inflicts a
                &ldquo;Prey Detected&rdquo; debuff on the target that used the
                main skill. All targeted attacks then focus towards the target
                marked with the debuff. The target&rsquo;s &ldquo;Prey
                Detected&rdquo; debuff is removed when another target receives
                the &ldquo;Prey Detected&rdquo; debuff or when &ldquo;Do not
                disturb!&rdquo; or &ldquo;Roar of Madness&rdquo; is used. If an
                enemy uses a main skill while she has the &ldquo;Erupting
                Madness&rdquo; buff, she gains 1 &ldquo;Madness&rdquo; stack
                (each stack increases her ATK by 5%, incoming damage by 3%).
                This buff stacks infinitely and enhances all of her skills.
              </td>
            </tr>
            <tr>
              <td>Lv. 201</td>
              <td>
                The mad beast activates the &ldquo;Roar of Madness&rdquo; skill
                during combat. When she uses the skill, she gains the
                &ldquo;Pent-up Madness&rdquo; buff which gives 1
                &ldquo;Madness&rdquo; buff every 2 seconds for 10 seconds when
                she has 3 or fewer debuffs.
              </td>
            </tr>
            <tr>
              <td>Lv. 280</td>
              <td>
                She can now stack up to 3 &ldquo;Erupting Madness&rdquo; buffs.
              </td>
            </tr>
            <tr>
              <td>Lv. 301</td>
              <td>
                If she doesn&rsquo;t have Madness, her &ldquo;Roar of
                Madness&rdquo; grants the &ldquo;Pent-up Madness&rdquo; buff,
                and she obtains 5 &ldquo;Madness&rdquo; buffs instead. Her buffs
                can no longer be canceled.
              </td>
            </tr>
            <tr>
              <td>Lv. 380</td>
              <td>
                Duration of &ldquo;Pent-up Madness&rdquo; changes to 20 seconds.
              </td>
            </tr>
          </tbody>
        </Table>
        <SectionHeader title="Notable Characters and Example Teams" />
        <p>
          Since progressing through ESS has the option to build many teams, a
          lot of which will depend on your own roster and character investments
          on ascensions and artifacts, this guide will only include some team
          and character suggestions, not static team builds or play order.{' '}
        </p>
        <p>
          Overall there are 2 main things to remember when building teams: CC
          and literally any kind of negative status effect. Since all DoT and
          debuffs count toward keeping Aira from gaining Madness stacks, any
          combination of them works, as long as there is enough uptime to always
          have 3 active.
        </p>
        <h5>Startup team / Bar depletion</h5>
        <p>
          Unless you’ve got a particularly well-stacked roster, using throwaway
          teams that help deplete the Groggy bar before you use your main DPS
          teams is a good strategy. These teams usually have characters on them
          that have a decent amount of CC but aren’t expected to do much damage
          or live very long.
        </p>
        <p>Special Mention:</p>
        <div className="employee-container for-nikke">
          <EversoulCharacter mode="icon" slug="naiah" enablePopover />
        </div>
        <p>
          Being a Fairy in this fight isn’t a good time, so unless your Naiah is
          pretty well invested, she’s likely just going to be cannon fodder for
          your throwaway CC team. She is amazing at nuking the Groggy bar though
          - so if you need a quick way to take down the bar, she’s your girl!
          It’s even possible to use multiple Naiah throughout your teams so if
          you have extras lying around that you can throw in sync - it’s
          honestly not a bad option.
        </p>
        <h5>Team Suggestions</h5>
        <StaticImage
          src="../../../images/eversoul/aira/image8.webp"
          alt="Aira Sub"
        />
        <p>
          A decent setup for big DPS and breaking the Groggy bar. If your Naiah
          isn’t decently invested, she can be replaced with other options that
          apply debuffs to keep Aira from gaining stacks from Pent-up Madness,
          or help break the Groggy bar.
        </p>
        <StaticImage
          src="../../../images/eversoul/aira/image9.webp"
          alt="Aira Sub"
        />
        <p>
          The usual Lize DPS team, nothing new here except that it will want the
          groggy bar to be close to broken since stuns don’t do as much to Aira
          as they did in other ESS.
        </p>
        <StaticImage
          src="../../../images/eversoul/aira/image6.webp"
          alt="Aira Sub"
        />
        <p>
          This team is a decent filler if you need extra DPS and Groggy bar
          management in between your big DPS teams. It has pretty good
          survivability, especially at higher investment, and has a mid-tier
          amount of CC that you can use to lower, but not break, the Groggy bar.
        </p>
        <h5>Other options</h5>
        <p>
          The usual undead team featuring Violette/Nini/Garnet still brings big
          DPS as always, but will need Groggy bar setup to avoid dying. Adding
          Jiho to the team in place of Prim is suggested. The fifth member is
          variable.
        </p>
        <p>
          Beast teams work well as filler, as the Mica/Seeha combination has
          great CC and survivability, and the beast roster overall works well
          together as a Groggy depletion team.
        </p>
        <p>
          Claudia and Erusha can work well together for decent DPS, if you have
          3 extra units to put with them to help keep them alive. Leftover Fairy
          units (Chloe, Talia, Renee) are decent filler options. Beast options
          are good too as long as they are not named Honglan.
        </p>
        <p>
          Human units deal bonus damage to Aira, so most decently invested
          humanlike units will be helpful here. Cherrie, Mephi, and Naomi all
          work quite well.
        </p>
      </div>
      <div className="fuse-ad-placeholder" data-fuse="22844297238"></div>
    </DashboardLayout>
  );
};

export default EsGuidesIAira;

export const Head: React.FC = () => (
  <Seo
    title="Subjugation - Aira | Eversoul | Prydwen Institute"
    description="Struggling with the Subjugation - Aira? This guide will help you!"
    game="eversoul"
  />
);
